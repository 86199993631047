import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TextField, Button, Grid, Paper, Dialog,
  MenuItem, DialogTitle, DialogContent, InputLabel, 
  DialogActions, Select, FormControl, FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enAU from 'date-fns/locale/en-AU';
import SpeechToTextField from '../../common/SpeechToTextField';
import { useSharedData } from '../../../utils/SharedDataContext';

const IncidentForm = ({ open, handleClose, mode, incidentData }) => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const {staffNameIds, locationNameIds, participantNameIds} = useSharedData();

  const [listData, setListData] = useState({
    to_staffsList: staffNameIds ? staffNameIds.filter(n => n.id !== loggedInUser.id) : []
  });

  const [formData, setFormData] = useState({
    date: new Date(),
    time: new Date(),
    staff_id: loggedInUser && loggedInUser.first_name + ' ' + loggedInUser.last_name,
    witness: '',
    participant_id: '',
    location_id: '',
    incident_type: '',
    category: '',
    incident_details: '',
    client_updates: '',
    actions_taken: '',
    risk_assessment: '',
    additional_notes: ''
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (staffNameIds) {
      setListData({...listData, to_staffsList: staffNameIds.filter(n => n.id !== loggedInUser.id)});
    }
  }, [staffNameIds]);

  useEffect(() => {
    if (mode && mode !== 'add' && incidentData) {
      setFormData({
        ...incidentData,
        witness: staffNameIds.find(s => s.id == incidentData.witness).name,
        // staff_id: staffNameIds.find(s => s.id === incidentData.staff_id).name,
        // participant_id: participantNameIds.find(p => p.id === incidentData.participant_id).name , 
        // location_id: locationNameIds.find(l => l.id === incidentData.location_id).name ,
        date: incidentData.date ? new Date(incidentData.date) : new Date(),
        time: incidentData.time ? new Date(`1970-01-01T${incidentData.time}`) : new Date(),
      });
    } else {
      setFormData(prev => ({
        ...prev,
        date: new Date(),
        time: new Date(),
      }));
    }
  }, [mode, incidentData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Clear error when field is changed
    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
  };

  const handleDateChange = (newDate) => {
    setFormData({ ...formData, date: newDate });
    if (errors.date) {
      setErrors({ ...errors, date: '' });
    }
  };

  const handleTimeChange = (newTime) => {
    setFormData({ ...formData, time: newTime });
    if (errors.time) {
      setErrors({ ...errors, time: '' });
    }
  };

  const getIdFromStaffName = (name) => {
    const selectedStaff = staffNameIds && staffNameIds.find(staff => staff.name === name);
    return selectedStaff && selectedStaff.id;
  }

  const validateForm = () => {
    const newErrors = {};
    if (!formData.date) newErrors.date = 'Date is required';
    if (!formData.time) newErrors.time = 'Time is required';
    if (!formData.witness) newErrors.witness = 'Witness is required';
    if (!formData.category) newErrors.category = 'Category is required';
    if (!formData.incident_type) newErrors.incident_type = 'Incident type is required';
    if (!formData.participant_id) newErrors.participant_id = 'Participant is required';
    if (!formData.location_id) newErrors.location_id = 'Location is required';
    if (!formData.incident_details) newErrors.incident_details = 'Incident details are required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const submissionData = {
        ...formData,
        staff_id: loggedInUser.id,
        witness: getIdFromStaffName(formData.witness),
        date: formData.date.toISOString().split('T')[0],
        time: formData.time.toTimeString().split(' ')[0].slice(0, 5),
      };
      if (mode && (mode === 'add' || mode === 'edit')) {
        handleClose(submissionData);
      } else {
        handleClose(null);
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{background:'linear-gradient(to left, darkred, navy)', color:'white'}}>
          {mode && mode === 'add' ? 'Add Incident' : mode && mode === 'edit' ? 'Update Incident ' : 'View Incident' }
        </DialogTitle>
        <DialogContent>
          <Paper elevation={3} style={{ padding: '10px', margin: '10px auto' }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Date"
                    value={formData.date}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField 
                        {...params} 
                        fullWidth 
                        required 
                        error={!!errors.date}
                        helperText={errors.date}
                      />
                    )}
                    disabled={mode === 'view'}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TimePicker
                    label="Time"
                    value={formData.time}
                    onChange={handleTimeChange}
                    renderInput={(params) => (
                      <TextField 
                        {...params} 
                        fullWidth 
                        required 
                        error={!!errors.time}
                        helperText={errors.time}
                      />
                    )}
                    disabled={mode === 'view'}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="staff_id"
                    label="Incident Reporter"
                    value={loggedInUser.first_name+' '+ loggedInUser.last_name}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth error={!!errors.witness}>
                    <InputLabel id="witness_label">Incident Witness</InputLabel>
                    <Select
                      labelId='witness_label'
                      id="witnessId"
                      name="witness"
                      label="Incident Witness"
                      value={formData.witness}
                      onChange={handleChange}
                      required
                      disabled={mode === 'view'}
                    >
                      {listData.to_staffsList && listData.to_staffsList.map((staff) => (
                        <MenuItem key={staff.id} value={staff.name}>
                          {staff.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.witness && <FormHelperText>{errors.witness}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth error={!!errors.category}>
                    <InputLabel id="category_label">Incident Category</InputLabel>
                    <Select
                      labelId='category_label'
                      id="categoryId"
                      name="category"
                      label="Incident Category"
                      value={formData.category}
                      onChange={handleChange}
                      required
                      disabled={mode === 'view'}
                    >
                      {ndisIncidentCategories.map((cat) => (
                        <MenuItem key={cat} value={cat}
                        >
                          {cat}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.category && <FormHelperText>{errors.category}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth error={!!errors.incident_type}>
                    <InputLabel id="incident_type_label">Incident Type</InputLabel>
                    <Select
                      labelId='incident_type_label'
                      id="incident_typeId"
                      name="incident_type"
                      label="Incident Type"
                      value={formData.incident_type}
                      onChange={handleChange}
                      required
                      disabled={mode === 'view'}
                    >
                      {ndisIncidentTypes.map((type) => (
                        <MenuItem key={type} value={type}
                        >
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.incident_type && <FormHelperText>{errors.incident_type}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth error={!!errors.participant_id}>
                    <InputLabel id="participant_label">Participant</InputLabel>
                    <Select
                      labelId='participant_label'
                      id="participantId"
                      name="participant_id"
                      label="Participant"
                      value={formData.participant_id}
                      onChange={handleChange}
                      required
                      disabled={mode === 'view'}
                    >
                      {participantNameIds && participantNameIds.map((p) => (
                        <MenuItem key={p.id} value={p.id}
                        >
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.participant_id && <FormHelperText>{errors.participant_id}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth error={!!errors.location_id}>
                    <InputLabel id="location_label">Location</InputLabel>
                    <Select
                      labelId='location_label'
                      id="locationId"
                      name="location_id"
                      label="Location"
                      value={formData.location_id}
                      onChange={handleChange}
                      required
                      disabled={mode === 'view'}
                    >
                      {locationNameIds && locationNameIds.map((l) => (
                        <MenuItem key={l.id} value={l.id}
                        >
                          {l.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.location_id && <FormHelperText>{errors.location_id}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <SpeechToTextField
                    name="incident_details"
                    label="Incident Details"
                    multiline
                    rows={4}
                    value={formData.incident_details}
                    onChange={handleChange}
                    required
                    disabled={mode === 'view'}
                    error={!!errors.incident_details}
                    helperText={errors.incident_details}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SpeechToTextField
                    name="actions_taken"
                    label="Actions Taken"
                    multiline
                    rows={4}
                    value={formData.actions_taken}
                    onChange={handleChange}
                    
                    disabled={mode === 'view'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SpeechToTextField
                    name="risk_assessment"
                    label="Risk Assesment"
                    multiline
                    rows={4}
                    value={formData.risk_assessment}
                    onChange={handleChange}
                    
                    disabled={mode === 'view'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SpeechToTextField
                    name="client_updates"
                    label="Client Updates"
                    multiline
                    rows={4}
                    value={formData.client_updates}
                    onChange={handleChange}
                    
                    disabled={mode === 'view'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SpeechToTextField
                    name="additional_notes"
                    label="Additional Notes"
                    multiline
                    rows={4}
                    value={formData.additional_notes}
                    onChange={handleChange}
                    
                    disabled={mode === 'view'}
                  />
                </Grid>
              </Grid>
            </form>
          </Paper>
        </DialogContent>
        
          <DialogActions>
            <Button onClick={handleClose} color="secondary">Cancel</Button>
            {mode !== 'view' && (
              <Button onClick={handleSubmit} color="primary">{mode === 'add' ? 'Submit' : 'Update'}</Button>
            )}
          </DialogActions>

      </Dialog>
    </LocalizationProvider>
  );
};


const ndisIncidentTypes = [
  "Injury",
  "Abuse",
  "Neglect",
  "Exploitation",
  "Death",
  "Restrictive Practices",
  "Medication Error",
  "Property Damage",
  "Unlawful Conduct",
  "Behavioral Incidents",
  "Self-harm",
  "Missing Person",
  "Financial Abuse",
  "Conflicts of Interest",
  "Breach of Privacy",
  "Service Delivery Failure"
];

const ndisIncidentCategories = [
  "Reportable Incidents",
  "Non-Reportable Incidents",
  "Restrictive Practices",
  "Behavioral Incidents",
  "Service Delivery Incidents",
  "Workplace Health and Safety Incidents"
];

export default IncidentForm;