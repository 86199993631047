import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  OutlinedInput,
  ToggleButtonGroup,
  ToggleButton,
  FormLabel,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { searchTimesheetsRequest } from "../../../redux/actions/timesheetActions";
import rangeOfDates from "../../../utils/DateUtils";
import MenuProps from "../../common/MenuProps";
import enAU from 'date-fns/locale/en-AU';

const SearchComponent = ({ employeesList, onSearch }) => {

  const MANAGER_ROLES = ['Manager', 'Team Lead'];
  const [dateSelection, setDateSelection] = useState("week");
  const loggedInUser = useSelector((state) => state.auth.user);
  const { data } = useSelector((state) => state.timesheet);
  const isManager = loggedInUser && MANAGER_ROLES.includes(loggedInUser.role);
  const [formData, setFormData] = useState({
    selectedEmployees: [],
    startTime: null,
    endTime: null,
    status: "",
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const handleDateSelection = (event, newSelection) => {
    setDateSelection(newSelection);
    if (newSelection !== "custom") {
      setFormData({ ...formData, ...rangeOfDates(newSelection) });
    }
  };

  const handleReset = () => {
    setDateSelection("week");
    setFormData({
      selectedEmployees: [],
      ...rangeOfDates("week"),
      status: "",
    });
    setErrors({});
  };

  const handleDataChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = (formattedData) => {
    let tempErrors = {};
    tempErrors.startTime = formattedData.startTime
      ? ""
      : "Start Date is required";
    tempErrors.endTime = formattedData.endTime ? "" : "End Date is required";

    if (
      formattedData.startTime &&
      formattedData.endTime &&
      formattedData.endTime <= formattedData.startTime
    ) {
      tempErrors.endTime = "End Date must be after Start Date";
    }

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  useEffect(() => {
    console.log("loggedin user role=",loggedInUser.role);

    if (!data){
      dispatch(searchTimesheetsRequest(searchTimesheets()));
      console.log("dispatched searchTimesheetsRequest");
    }
  }, []);

  const searchTimesheets = () => {
    if (!isManager) { 
      handleDataChange('selectedEmployees', [loggedInUser.first_name+' '+loggedInUser.last_name]);
      console.log("handleDataChange for select empl");
    }
    let formattedData ;
    if (formData.startTime) {
      formattedData = {
        ...formData,
        startTime: (formData.startTime.valueOf() / 1000) | 0,
        endTime: (formData.endTime.valueOf() / 1000) | 0,
        ...(!isManager && {'selectedEmployees': [loggedInUser.first_name+' '+loggedInUser.last_name]}),
      };
    } else {
      const wekDt = rangeOfDates("week");
      setFormData({ ...formData, ...wekDt});
      formattedData = {
        ...formData,
        startTime: (wekDt.startTime.valueOf() / 1000) | 0,
        endTime: (wekDt.endTime.valueOf() / 1000) | 0,
        ...(!isManager && {'selectedEmployees': [loggedInUser.first_name+' '+loggedInUser.last_name]}),
      };
    }
    console.log("in  searchTimesheets", JSON.stringify(formData));
    return formattedData;
  };
  const handleSearch = (e) => {
    e.preventDefault();
    let formattedData = searchTimesheets();
    if (validateForm(formattedData)) {
      onSearch(formattedData);
    } else {
      console.log(
        "validation failed formattedData=>",
        JSON.stringify(formattedData)
      );
    }
  };

  const handleMultiSelect = (selected) => {
    

  }
  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    handleDataChange("selectedEmployees",
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}  adapterLocale={enAU}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {isManager &&
        <FormControl variant="outlined" sx={{ mb:1, textAlign: "left" }}>
          <InputLabel id="employee-label" >Employees</InputLabel>
          <Select
            labelId="employee-label"
            id="multiple-checkbox"
            multiple
            value={formData.selectedEmployees}
            onChange={handleSelectChange}
            input={<OutlinedInput label="Employees" />}
            renderValue={(selected) => selected.map(idSel => employeesList.find(e => e.id === idSel).name).join(', ') }
            MenuProps={MenuProps}
          >
            {employeesList && employeesList.map((e) => (
              <MenuItem key={e.id} value={e.id}>
                <Checkbox checked={formData.selectedEmployees.indexOf(e.name) > -1} />
                <ListItemText primary={e.name} />
              </MenuItem>
            ))}
          </Select>

        </FormControl>
        }
        <FormControl variant="outlined">
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            id="status-select"
            value={formData.status}
            onChange={(e) => handleDataChange("status", e.target.value)}
            input={<OutlinedInput label="Status" />}
            sx={{ textAlign: "left" }}
          >
            <MenuItem value="Approved">Approved</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ textAlign: "left" }}>
          <FormLabel id="date-select-label" sx={{fontSize:'1em', mb:1}} >
            Date Range
          </FormLabel>
          {/* <RadioGroup
            name="controlled-radio-buttons-group"
            value={dateSelection}
            onChange={handleDateSelection}
          >
            <FormControlLabel value="week" control={<Radio />} label="Week" />
            <FormControlLabel
              value="fortnight"
              control={<Radio />}
              label="Fortnight"
            />
            <FormControlLabel value="month" control={<Radio />} label="Month" />
            <FormControlLabel
              value="custom"
              control={<Radio />}
              label="Custom"
            />
          </RadioGroup> */}

          <ToggleButtonGroup
            value={dateSelection}
            exclusive
            onChange={handleDateSelection}
            size='small'
            color="secondary"
            sx={{mb:2,flexWrap: "wrap"}}
          >
            <ToggleButton sx={{fontSize:'0.65em', fontWeight: 'bold'}} value="week">Week</ToggleButton>
            <ToggleButton sx={{fontSize:'0.65em', fontWeight: 'bold'}} value="fortnight">Fortnight</ToggleButton>
            <ToggleButton sx={{fontSize:'0.65em', fontWeight: 'bold'}} value="month">Month</ToggleButton>
            <ToggleButton sx={{fontSize:'0.65em', fontWeight: 'bold'}} value="custom">Custom</ToggleButton>
          </ToggleButtonGroup>

              <DateTimePicker
                label="Start Date"
                value={formData.startTime}
                onChange={(newValue) => handleDataChange("startTime", newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                sx={{ mb: 2}}
                disabled={dateSelection && dateSelection !== "custom"}
                
              />
              <DateTimePicker
                label="End Date"
                value={formData.endTime}
                onChange={(newValue) => handleDataChange("endTime", newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                disabled={dateSelection && dateSelection !== "custom"}
              />

        </FormControl>
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Search
        </Button>
        <Button onClick={handleReset} variant="contained" color="primary">
          Reset
        </Button>
      </Box>
    </LocalizationProvider>
  );
};

export default SearchComponent;
