// sagas/timesheetSaga.js
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { fetchWithAuth } from '../../utils/fetchApi';
import {
  FETCH_TIMESHEETS_REQUEST,
  FETCH_DEPUTY_TIMESHEETS_REQUEST,
  fetchTimesheetsSuccess,
  fetchTimesheetsFailure,
  fetchDeputyTimesheetsSuccess,
  fetchDeputyTimesheetsFailure,
  SAVE_SUPERVISOR_COMMENT,
  saveSupervisorCommentSuccess,
  saveSupervisorCommentFailure,
  SEARCH_TIMESHEETS_REQUEST,
  UNDO_APPROVE_REQUEST,
  undoApproveSuccess,
  undoApproveFailure,
  fetchTimesheetsRequest,
  addTimesheetDataSuccess,
  addTimesheetDataFailure,
  ADD_NEW_TIMESHEET_REQUEST,
  DELETE_TIMESHEETS_REQUEST,
  deleteTimesheetsFailure,
  deleteTimesheetsSuccess,
  saveDeputyDataSuccess,
  saveDeputyDataFailure,
  SAVE_DEPUTY_DATA_REQUEST,
  searchTimesheetsRequest,
  updateTimesheetSuccess,
  updateTimesheetFailure,
  UPDATE_TIMESHEET_REQUEST,
} from '../actions/timesheetActions';

function* fetchTimesheets() {
  const searchCriteria = yield select((state => state.timesheet.searchCriteria));
  console.log("fetch timesheets - > searchCriteria",searchCriteria);
  yield put(searchTimesheetsRequest(searchCriteria));
}

function* fetchDeputyTimesheets(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/timesheets/deputy/search', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (response.ok) {
      yield put(fetchDeputyTimesheetsSuccess(data));
    } else {
      yield put(fetchDeputyTimesheetsFailure('Failed to fetch Deputy timesheets'));
    }
  } catch (error) {
    yield put(fetchDeputyTimesheetsFailure(error.message));
  }
}

function* searchTimesheets(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/timesheets/search', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (response.ok) {
      yield put(fetchTimesheetsSuccess(data));
    } else {
      yield put(fetchTimesheetsFailure('Failed to search timesheets'));
    }
  } catch (error) {
    yield put(fetchTimesheetsFailure(error.message));
  }
}

function* saveCommentInTimesheet(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/timesheets/comment', {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (response.ok) {
      yield put(saveSupervisorCommentSuccess(data));
      yield put(fetchTimesheetsRequest());
    } else {
      yield put(saveSupervisorCommentFailure('Failed to save supervisor comment'));
    }
  } catch (error) {
    yield put(saveSupervisorCommentFailure(error.message));
  }
}

function* undoApproveTimesheet(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/timesheets/undo', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (response.ok) {
      yield put(undoApproveSuccess(data));
      yield put(fetchTimesheetsRequest());
    } else {
      yield put(undoApproveFailure('Failed to undo timesheet '));
    }
  } catch (error) {
    yield put(undoApproveFailure(error.message));
  }
}

function* addTimesheetData(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/timesheets/new', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (response.ok) {
      yield put(addTimesheetDataSuccess(data.msg));
      yield put(fetchTimesheetsRequest());
    } else {
      yield put(addTimesheetDataFailure(data.error));
    }
  } catch (error) {
    yield put(addTimesheetDataFailure(error.message));
  }
}

function* deleteTimesheetsData(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/timesheets/remove', {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (response.ok) {
      yield put(deleteTimesheetsSuccess(data));
      yield put(fetchTimesheetsRequest());
    } else {
      yield put(deleteTimesheetsFailure('Failed to remove timesheet '));
    }
  } catch (error) {
    yield put(deleteTimesheetsFailure(error.message));
  }
}

function* saveDeputyData(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/timesheets/deputy/save', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (response.ok) {
      if (data.failed_to_add && data.failed_to_add.length > 0) {
        let errorMessages = [data.msg + ' Following timesheets were rejected.'];
        for (let tm of data.failed_to_add) {
          errorMessages.push(`Staff id: ${tm.timesheet.staff_id}, Start Time: ${new Date(tm.timesheet.start_time*1000).toLocaleString()}, Total Hours: ${tm.timesheet.total_time}`);
        }
  
        yield put(saveDeputyDataFailure(errorMessages));
      } else {
        yield put(saveDeputyDataSuccess(data.msg));
      }
    } else {
      yield put(saveDeputyDataFailure(data.error));
    }
  } catch (error) {
    yield put(saveDeputyDataFailure(error.message));
  }
}

function* updateTimesheetData(action) {
  try {
    const response = yield call(fetchWithAuth, `/api/timesheets/${action.payload.id}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (response.ok) {
      yield put(updateTimesheetSuccess(data));
      yield put(fetchTimesheetsRequest());
    } else {
      yield put(updateTimesheetFailure('Failed to update timesheet '));
    }
  } catch (error) {
    yield put(updateTimesheetFailure(error.message));
  }
}


export default function* watchTimesheet() {
  yield takeLatest(FETCH_TIMESHEETS_REQUEST, fetchTimesheets);
  yield takeLatest(FETCH_DEPUTY_TIMESHEETS_REQUEST, fetchDeputyTimesheets);
  yield takeLatest(SEARCH_TIMESHEETS_REQUEST, searchTimesheets);
  yield takeLatest(SAVE_SUPERVISOR_COMMENT, saveCommentInTimesheet);
  yield takeLatest(UNDO_APPROVE_REQUEST, undoApproveTimesheet);
  yield takeLatest(ADD_NEW_TIMESHEET_REQUEST, addTimesheetData);
  yield takeLatest(DELETE_TIMESHEETS_REQUEST, deleteTimesheetsData);
  yield takeLatest(SAVE_DEPUTY_DATA_REQUEST, saveDeputyData);
  yield takeLatest(UPDATE_TIMESHEET_REQUEST, updateTimesheetData);
}
