import {

  SEARCH_PROCESSED_TIMESHEETS_REQUEST,
  SEARCH_PROCESSED_TIMESHEETS_SUCCESS,
  SEARCH_PROCESSED_TIMESHEETS_FAILURE,
  SAVE_HOURS_ADJUSTED_REQUEST,
  SAVE_CATEGORY_CHANGE_REQUEST,
} from '../actions/timesheetActions';
import getGroupedByStaffId from "../../utils/DataUtils";

const initialState = {
  processing: false,
  processed: [],
  error: null,
};

const processedTimesheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_PROCESSED_TIMESHEETS_REQUEST:
      return {
        ...state,
        error: null,
        processing: true
      };
    case SEARCH_PROCESSED_TIMESHEETS_SUCCESS:
      return {
        ...state,
        processed: getGroupedByStaffId(action.payload),
        error: null,
        processing: false
      };
    case SEARCH_PROCESSED_TIMESHEETS_FAILURE:
      return {
        ...state,
        processed: null,
        error: action.payload,
        processing: false,
      };
    case SAVE_HOURS_ADJUSTED_REQUEST:
      return {
        ...state,
        error:null,
        processed: getHourAdjusted(state.processed, action.payload),
      }
    case SAVE_CATEGORY_CHANGE_REQUEST:
      return {
        ...state,
        error:null,
        processed: saveCategoryChange(state.processed, action.payload),      
      }

    default:
      return state;
  }
};

const getHourAdjusted = (data, req) => {
  for(let item of data) {
    const selectedRowArrray = item.timesheetData.filter((tmData) => tmData.id === req.id);
    let selectedRow = selectedRowArrray && selectedRowArrray[0];
    if (selectedRow) {
      selectedRow.hours_adjusted = req.hours_adjusted;
      selectedRow.hours_approved += Number(req.hours_adjusted);
      break;
    }
  }
  return data;
}

const saveCategoryChange= (data, req) => {
  for(let item of data) {
    const selectedRowArrray = item.timesheetData.filter((tmData) => tmData.id === req.id);
    let selectedRow = selectedRowArrray && selectedRowArrray[0];
    if (selectedRow) {
      selectedRow.shift_category = req.shift_category;
      selectedRow.hours_approved = 1;
      break;
    }
  }
  return data;
}
 
export default processedTimesheetReducer;
