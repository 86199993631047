import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchWithAuth } from  '../../utils/fetchApi';
import { incidentActions } from '../actions/incidentActions';

function* addIncidentSaga(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/incidents/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to add new Incident');
    }

    yield put(incidentActions.addIncidentSuccess(data));
  } catch (error) {
    yield put(incidentActions.addIncidentFailure(error.message));
  }
}

function* modifyIncidentSaga(action) {
  try {
    const response = yield call(fetchWithAuth, `/api/incidents/${action.payload.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to modify Incident');
    }

    yield put(incidentActions.modifyIncidentSuccess(data));
  } catch (error) {
    yield put(incidentActions.modifyIncidentFailure(error.message));
  }
}

function* deleteIncidentSaga(action) {
  try {
    const response = yield call(fetchWithAuth, `/api/incidents/${action.payload}`, {
      method: 'DELETE',
    });
    const data = yield response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to delete new Incident');
    }

    yield put(incidentActions.deleteIncidentSuccess(data.msg, action.payload));
  } catch (error) {
    yield put(incidentActions.deleteIncidentFailure(error.message));
  }
}

function* getIncidentsSaga(action) {
  try {
    let url = '/api/incidents/list';
    if (action.payload) {
      const params = new URLSearchParams();
      const { startTime, endTime, participantId, locationId, staffId } = action.payload;
      if (startTime && endTime) {
        params.append('from_date', startTime);
        params.append('to_date', endTime);
      }
      if (participantId) {
        params.append('participant_id', participantId);
      }
      if (locationId) {
        params.append('location_id', locationId);
      }
      if (staffId) {
        params.append('staff_id', staffId);
      }
      if (params.toString()) {
        url += `?${params.toString()}`;
      }
    }
    const response = yield call(fetchWithAuth, url, {
      method: 'GET'
    });

    if (!response.ok) {
      throw new Error('Failed to get list of incidents');
    }
    const data = yield response.json();
    yield put(incidentActions.getIncidentsSuccess(data));
  } catch (error) {
    yield put(incidentActions.getIncidentsFailure(error.message));
  }
}

export default function* incidentSaga() {
  yield takeLatest('ADD_INCIDENT_REQUEST', addIncidentSaga);
  yield takeLatest('MODIFY_INCIDENT_REQUEST', modifyIncidentSaga);
  yield takeLatest('GET_INCIDENTS_REQUEST', getIncidentsSaga);
  yield takeLatest('DELETE_INCIDENT_REQUEST', deleteIncidentSaga);
}