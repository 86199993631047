import React from 'react';
import { Alert, Box, Button } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { integrationActions } from '../../../redux/actions/integrationActions';
import { emailReportRequest } from '../../../redux/actions/reportActions';
import { calculateOvertimeRequest } from '../../../redux/actions/shiftActions';

const columns = [
    {
      field: 'shift_range',
      renderHeader: () => (
        <strong>
          {'Fortnight'}
        </strong>
      ),
      flex: 1,
      editable: false
    },
    {
      field: 'shift_item_code',
      renderHeader: () => (
        <strong>
          {'Pay Item Code'}
        </strong>
      ),
      flex: 1,
      editable: false
    },
    {
      field: 'shift_category',
      renderHeader: () => (
        <strong>
          {'Shift Category'}
        </strong>
      ),
      headerName: 'Shift Category',
      flex: 1,
      editable: false,
    },
    {
      field: 'hours_approved',
      renderHeader: () => (
        <strong>
          {'Hours Approved'}
        </strong>
      ),
      headerAlign: 'left',
      type: 'number',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          row.hours_approved >10?
          <Box sx={{color:'red'}}>
            {Math.round(row.hours_approved * 100) / 100}
          </Box>
          : Math.round(row.hours_approved * 100) / 100
        )
      },
    },
  
  ];

const FortnightViewDataGrid = ({data, id}) => {
  const dispatch = useDispatch();

  const { message, error } = useSelector(state => state.report) 
  const { uploadMessage, uploadError } = useSelector(state => state.integration.xero) 

  const handlePayrollSubmit = () => {
    console.log("id in datagrid = ", id)
    dispatch(integrationActions.xeroUploadRequest(id));
  }

  const handleReportEmailSubmit = () => {
    dispatch(emailReportRequest(id));
  }

  const handleCalcOvertimeClick = () => {
    console.log('calacovertimeclicked');
    if (data && data[0] && data[0].shift_range) {

      const dates = data[0].shift_range.match(/(\d{2}\/\d{2}\/\d{4})/g);
      const startTime = getDateInUnixTimestamp(dates[0]);
      const endTime = getDateInUnixTimestamp(dates[1]);
      console.log("Extraced dates start =>",startTime);
      console.log("Extraced dates end=>",endTime);

      dispatch(calculateOvertimeRequest({selectedEmployees:[id], startTime:startTime, endTime:endTime}));

    }
  }

  const getDateInUnixTimestamp=(dateString)=>{
    const dateParts = dateString.split("/");
    // month is 0-based, that's why we need dataParts[1] - 1
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]).valueOf()/1000; 
  }

  const handleAddPayslipItemClick = () => {
    console.log('handleAddPayslipItemClick');
  }

  const sum = data.reduce((acc, curr) => acc + curr.hours_approved, 0);
  const CustomFooterStatusComponent =({sum}) => {
    return (
        sum > 76?
        <Box sx={{ color:'red', p: 1, display: "flex", justifyContent: "end", fontWeight:'bold', backgroundColor:'whitesmoke' }}>
          Total {sum}
        </Box>
        :
        <Box sx={{ p: 1, display: "flex", justifyContent: "end", fontWeight:'bold', backgroundColor:'whitesmoke' }}>
          Total {sum}
        </Box>
      );
  }
  return (
    <Box p={2}>
      
      <Box display="flex" gap={1} mb={2}>
        <Button variant="contained" color="error" onClick={handleCalcOvertimeClick}>Calculate Fortnight Overtime</Button>
        <Button variant="contained" color="primary" onClick={handleAddPayslipItemClick}>Add Item</Button>
        <Button variant="contained" color="secondary" onClick={handlePayrollSubmit}>Submit to Payroll</Button>
        <Button variant="contained" color="info" onClick={handleReportEmailSubmit}>Send Email</Button>
        {message && <Alert severity='success' >{message}</Alert>}
        {error && <Alert severity='error' >{error} </Alert>}
        {uploadMessage && <Alert severity='success' >{uploadMessage}</Alert>}
        {uploadError && <Alert severity='error' >{uploadError} </Alert>}
      </Box>
      <Box sx={{ height: 500, width: '100%' }}>
        <DataGrid autoHeight getRowId={(row) => row.id}
        sx={{ "& .MuiDataGrid-withBorderColor": { backgroundColor: '#f7f7f7' }}}
          rows={data}
          columns={columns}
          slots={{
            // toolbar: GridToolbar,
            footer: CustomFooterStatusComponent,
          }}
          slotProps={{
          //   columnsManagement: {
          //     getTogglableColumns,
          //   },
          footer: { sum },
          }}
          //isRowSelectable={(params) => params.row.Status == 'Pending'}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 7,
              },
            },
          }}
          pageSizeOptions={[7]}
          // checkboxSelection
          // disableRowSelectionOnClick
          // onRowSelectionModelChange={(newRowSelectionModel) => {
          //   setRowSelectionModel(newRowSelectionModel);
          // }}
          // rowSelectionModel={rowSelectionModel}
        />
      </Box>
    </Box>
  );
};

export default FortnightViewDataGrid;
