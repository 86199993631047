// sagas/processShiftSaga.js
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  PROCESS_SHIFT_REQUEST,
  processShiftSuccess,
  processShiftFailure,
  calculateOvertimeFailure,
  calculateOvertimeSuccess,
  CALCULATE_OVERTIME_REQUEST,
} from '../actions/shiftActions';
import {
  fetchTimesheetsRequest
} from '../actions/timesheetActions';

import { fetchWithAuth } from '../../utils/fetchApi';

function* processShift(action) {
  try {

    const response = yield call(fetchWithAuth, '/api/shifts/process', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response;

    if (data) {
      yield put(processShiftSuccess(data));
      yield put(fetchTimesheetsRequest());
    } else {
      yield put(processShiftFailure(data));
    }
  } catch (error) {
    yield put(processShiftFailure(error));
  }
}

function* calculateOvertime(action) {
  try {

    const response = yield call(fetchWithAuth, '/api/shifts/overtime', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response;

    if (data) {
      yield put(calculateOvertimeSuccess(data));
    } else {
      yield put(calculateOvertimeFailure(data));
    }
  } catch (error) {
    yield put(calculateOvertimeFailure(error));
  }
}

export default function* processShiftSaga() {
  yield takeLatest(PROCESS_SHIFT_REQUEST, processShift);
  yield takeLatest(CALCULATE_OVERTIME_REQUEST, calculateOvertime);
}
