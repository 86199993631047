import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Checkbox
} from "@mui/material";
import { incidentActions } from '../../../redux/actions/incidentActions';
import IncidentForm from './IncidentForm';

const IncidentView = ({filter, onCheck, incidentsIn, mode}) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState({});
  const { incidents } = useSelector(state => state.incident);
  const [open, setOpen] = useState(false);
  const [incidentData, setIncidentData] = useState();
  const [incidentsData, setIncidentsData] = useState([]);

  const handleChange = useCallback((event, id) => {
    setChecked(prevChecked => {
      const newChecked = { ...prevChecked, [id]: event.target.checked };
      const selectedIncidents = incidentsData.filter(incident => newChecked[incident.id]);
      onCheck(selectedIncidents);
      return newChecked;
    });
  }, [incidentsData, onCheck]);

  useEffect(() => {
    const updateIncidentsData = () => {
      if (mode === 'modify' && incidentsIn && incidentsIn.length > 0) {
        if (incidents) {
          setIncidentsData(incidents);
        } else {
          setIncidentsData(incidentsIn);
        }
        setChecked(incidentsIn.reduce((acc, incident) => ({ ...acc, [incident.id]: true }), {}));
      } else if (mode === 'add' && incidents && incidents.length > 0) {
        setIncidentsData(incidents);
        setChecked(incidents.reduce((acc, incident) => ({ ...acc, [incident.id]: false }), {}));
      } else if (mode === 'view' && incidentsIn && incidentsIn.length > 0 ) {
        setIncidentsData(incidentsIn);
        setChecked(incidentsIn.reduce((acc, incident) => ({ ...acc, [incident.id]: true }), {}));
      } else {
        setIncidentsData([]);
        setChecked({});
      }
    };

    updateIncidentsData();

  }, [ incidentsIn, incidents, dispatch]);

  useEffect(() => {
    if (filter && mode !== 'view') {
      dispatch(incidentActions.getIncidentsRequest(filter));
    }
  },[])

  const handleRowClick = useCallback((rowData) => {
    setOpen(true);
    setIncidentData(rowData);
  }, []);

  const handleIncidentViewClose = useCallback(() => {
    setOpen(false);
    setIncidentData(null);
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{backgroundColor:'#4386f4'}}>
            <TableRow>
              {
                mode !== 'view' ?
                <TableCell sx={{color:'white',fontWeight:'bold'}}> 
                  <Checkbox disabled/>
                </TableCell>
                :
                <></>
              }
              <TableCell sx={{color:'white',fontWeight:'bold'}}>Category</TableCell>
              <TableCell sx={{color:'white',fontWeight:'bold'}}>Incident_type</TableCell>
              <TableCell sx={{color:'white',fontWeight:'bold'}}>date</TableCell>
              <TableCell sx={{color:'white',fontWeight:'bold'}}>time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {incidentsData.length > 0 ? 
              incidentsData.map((row, i) => (
                <TableRow
                  key={row.id || i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  
                    {
                    mode !== 'view' ?
                    <TableCell>
                      <Checkbox
                        checked={checked[row.id] || false}
                        onChange={(event) => handleChange(event, row.id)}
                      />
                    </TableCell>
                      : <></>
                    }
                  
                  <TableCell onClick={() => handleRowClick(row)}>{row.category}</TableCell>
                  <TableCell onClick={() => handleRowClick(row)}>{row.incident_type}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.time}</TableCell>
                </TableRow>
              ))
              :
              <TableRow>
                <TableCell colSpan={5}>No Incidents during this period</TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <IncidentForm
        open={open}
        handleClose={handleIncidentViewClose}
        mode={'view'}
        incidentData={incidentData}
      />
    </>
  );
};

export default IncidentView;