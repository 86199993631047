
export const PROCESS_SHIFT_REQUEST = 'PROCESS_SHIFT_REQUEST';
export const PROCESS_SHIFT_SUCCESS = 'PROCESS_SHIFT_SUCCESS';
export const PROCESS_SHIFT_FAILURE = 'PROCESS_SHIFT_FAILURE';

export const CALCULATE_OVERTIME_REQUEST = 'CALCULATE_OVERTIME_REQUEST';
export const CALCULATE_OVERTIME_SUCCESS = 'CALCULATE_OVERTIME_SUCCESS';
export const CALCULATE_OVERTIME_FAILURE = 'CALCULATE_OVERTIME_FAILURE';

export const processShiftRequest = (data) => ({
  type: PROCESS_SHIFT_REQUEST,
  payload: data,
});

export const processShiftSuccess = (data) => ({
  type: PROCESS_SHIFT_SUCCESS,
  payload: data,
});

export const processShiftFailure = (error) => ({
  type: PROCESS_SHIFT_FAILURE,
  payload: error,
});

export const calculateOvertimeRequest = (data) => ({
  type: CALCULATE_OVERTIME_REQUEST,
  payload: data,
});

export const calculateOvertimeSuccess = (data) => ({
  type: CALCULATE_OVERTIME_SUCCESS,
  payload: data,
});

export const calculateOvertimeFailure = (error) => ({
  type: CALCULATE_OVERTIME_FAILURE,
  payload: error,
});