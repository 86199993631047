
const getGroupedByStaffId = (data) => {
    let userTimesheets = [];
    let userlist = [];
    for (let row of data) {
      if (userlist.includes(row.staff_id)) {
        for (let insertedEmp of userTimesheets) {
          if (insertedEmp.staff_id === row.staff_id){
            insertedEmp.timesheetData.push(
              row
            )
          }
        }
      }
      else {
        userlist.push(row.staff_id);
        userTimesheets.push(
          {
            staff_id:row.staff_id,
            timesheetData: [
              row
            ]
          }
        )
      }
    }
    return userTimesheets
  };

export const replaceObjectInArrayById = (array, modifiedObject) => {
  const index = array.findIndex(obj => obj.id === modifiedObject.id);

  if (index !== -1) {
    array.splice(index, 1, modifiedObject);
  } else {
    // Handle case where object with matching ID is not found (optional)
    console.warn("Object with ID", modifiedObject.id, "not found in the array.");
  }
  return array;
}

export default getGroupedByStaffId;