import React, { useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '../../common/TimeSheetStyledAccordion';
import {Box, Typography} from '@mui/material';
import TimeSheetDataGrid from './TimeSheetDataGrid';
import { useSharedData } from '../../../utils/SharedDataContext';


const TimeSheetAccordian = ({data, onEdit, onView }) => {

  const { staffNameIds  } = useSharedData();
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(()=>{
    if (data && data[0]) {
      setExpanded(data[0].staff_id);
    }
  },[])
  return (
    <Box>
      {
        data && data.map( (employee, index) => (
          <Accordion key={index}  expanded={ expanded === employee.staff_id} onChange={handleChange(employee.staff_id)}>
            <AccordionSummary id={employee.staff_id+"-panel-header"} sx={{background:'linear-gradient(to left, darkred, navy)', color:"white", borderRadius: '6px'}}>
              <Typography sx={{fontWeight:'bold'}}>{staffNameIds.find(s => s.id === employee.staff_id)?.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TimeSheetDataGrid key={index} data={employee.timesheetData} onEdit={onEdit} onView={onView} />
            </AccordionDetails>
          </Accordion>    
        ))
      }
    </Box>
  );
}


export default TimeSheetAccordian;