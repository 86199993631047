export const incidentActions = {
    addIncidentRequest: (data) => ({ type: 'ADD_INCIDENT_REQUEST', payload: data }),
    addIncidentSuccess: (data) => ({ type: 'ADD_INCIDENT_SUCCESS', payload: data }),
    addIncidentFailure: (error) => ({ type: 'ADD_INCIDENT_FAILURE', payload: error }),
    getIncidentsRequest: (data) => ({ type: 'GET_INCIDENTS_REQUEST', payload: data }),
    getIncidentsSuccess: (data) => ({ type: 'GET_INCIDENTS_SUCCESS', payload: data }),
    getIncidentsFailure: (error) => ({ type: 'GET_INCIDENTS_FAILURE', payload: error }),
    deleteIncidentRequest: (data) => ({ type: 'DELETE_INCIDENT_REQUEST', payload: data }),
    deleteIncidentSuccess: (data, id) => ({ type: 'DELETE_INCIDENT_SUCCESS', payload: data, id }),
    deleteIncidentFailure: (error) => ({ type: 'DELETE_INCIDENT_FAILURE', payload: error }),
    modifyIncidentRequest: (data) => ({ type: 'MODIFY_INCIDENT_REQUEST', payload: data }),
    modifyIncidentSuccess: (data) => ({ type: 'MODIFY_INCIDENT_SUCCESS', payload: data }),
    modifyIncidentFailure: (error) => ({ type: 'MODIFY_INCIDENT_FAILURE', payload: error }),
  };