import React, { useState, useRef } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';

const SpeechToTextField = ({ value, onChange, label, name, rows, multiline, required, disabled, error, helperText }) => {
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);

  const handleStartListening = () => {
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();
      recognition.continuous = false;
      recognition.interimResults = false;
      recognition.lang = 'en-US';

      recognition.onstart = () => setIsListening(true);
      recognition.onend = () => setIsListening(false);

      recognition.onresult = (event) => {
        let transcript = event.results[0][0].transcript.trim();
        if (transcript) {
          const currentValue = value? value.trim():'';
          let newValue = currentValue;

          if (currentValue) {
            // If there's existing text, add appropriate punctuation
            if (!/[.!?]$/.test(currentValue)) {
              newValue += '.';
            }
            newValue += ' ';
          }

          // Append the new transcript
          newValue += transcript;

          // Ensure the new transcript ends with appropriate punctuation
          if (!/[.!?]$/.test(newValue)) {
            newValue += '.';
          }

          onChange({
            target: {
              name,
              value: newValue,
            },
          });
        }
      };

      recognition.onerror = (event) => {
        console.error("Speech recognition error", event.error);
        setIsListening(false);
      };

      recognitionRef.current = recognition;
      recognition.start();
    } else {
      console.log("Speech Recognition API is not supported in this browser.");
    }
  };

  const handleStopListening = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
  };

  return (
    <TextField
      fullWidth
      name={name}
      label={label}
      multiline={multiline}
      rows={rows}
      value={value}
      onChange={onChange}
      required={required}
      disabled={disabled}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onMouseDown={handleStartListening}
              onMouseUp={handleStopListening}
              onTouchStart={handleStartListening}
              onTouchEnd={handleStopListening}
              disabled={disabled}
            >
              <MicIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SpeechToTextField;