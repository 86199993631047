// reducers/timesheetReducer.js
import {
    FETCH_TIMESHEETS_SUCCESS,
    FETCH_TIMESHEETS_FAILURE,
    FETCH_DEPUTY_TIMESHEETS_REQUEST,
    FETCH_DEPUTY_TIMESHEETS_SUCCESS,
    FETCH_DEPUTY_TIMESHEETS_FAILURE,
    SAVE_DEPUTY_DATA_SUCCESS,
    SAVE_DEPUTY_DATA_FAILURE,
    SEARCH_TIMESHEETS_REQUEST,
    GET_TIMESHEET_REQUEST,
    GET_TIMESHEET_SUCCESS,
    GET_TIMESHEET_FAILURE,
    UPDATE_TIMESHEET_REQUEST,
    UPDATE_TIMESHEET_SUCCESS,
    UPDATE_TIMESHEET_FAILURE,
    ADD_NEW_TIMESHEET_REQUEST,
    ADD_NEW_TIMESHEET_SUCCESS,
    ADD_NEW_TIMESHEET_FAILURE,
  } from '../actions/timesheetActions';
import getGroupedByStaffId from "../../utils/DataUtils";

  const initialState = {
    loading: false,
    data: null,
    message: null,
    deputyData: null,
    deputyError: null,
    deputyMessage: null,
    searchCriteria: null,
    error: null,
    timesheet:null,
  };
  
  const timesheetReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_TIMESHEETS_SUCCESS:
        return { ...state, loading: false, data: getGroupedByStaffId(action.payload), error: null };
      case FETCH_TIMESHEETS_FAILURE:
        return { ...state, loading: false, error: action.payload };
      case SEARCH_TIMESHEETS_REQUEST:
        return { ...state, loading: true, searchCriteria: action.payload, error: null };
      case FETCH_DEPUTY_TIMESHEETS_REQUEST:
        return { ...state, loading: true, deputyError: null, deputyMessage: null  };
      case FETCH_DEPUTY_TIMESHEETS_SUCCESS:
        return { ...state, loading: false, deputyData: action.payload, deputyError: null, deputyMessage:'Successfully fetched data from Deputy' };
      case FETCH_DEPUTY_TIMESHEETS_FAILURE:
        return { ...state, loading: false, deputyError: action.payload, deputyMessage: null };
      case SAVE_DEPUTY_DATA_SUCCESS:
        return { ...state, loading: false, deputyMessage: action.payload, deputyError:null };
      case SAVE_DEPUTY_DATA_FAILURE:
        return { ...state, loading: false, deputyError: action.payload, deputyMessage: null };
      case GET_TIMESHEET_REQUEST:
      case UPDATE_TIMESHEET_REQUEST:
      case ADD_NEW_TIMESHEET_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
          message: null,
        };
      case GET_TIMESHEET_SUCCESS:
        return {
          ...state,
          loading: false,
          timesheet: action.payload,
          error: null,
        };
      case UPDATE_TIMESHEET_SUCCESS:
      case ADD_NEW_TIMESHEET_SUCCESS:
        return {
          ...state,
          loading: false,
          timesheet: action.payload,
          message: 'Timesheet saved successfully',
          error: null,
        };
      case GET_TIMESHEET_FAILURE:
      case UPDATE_TIMESHEET_FAILURE:
      case ADD_NEW_TIMESHEET_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
          message: null,
        };
      default:
        return state;
    }
  };
  
  export default timesheetReducer;
  