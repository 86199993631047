import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import ChatNotification from '../common/ChatNotification';

const Footer = () => {
  return (
    <AppBar position="static"  sx={{background:'linear-gradient(to left, darkred, navy)', position:'fixed', top: 'auto', bottom: 0 }}>
      <Toolbar sx={{justifyContent:'space-between'}}>
        <Typography variant="body2" color="inherit">
          &copy;{new Date().getFullYear()} Tekinef. All Rights Reserved.
        </Typography>
        <Typography variant="body1" color="inherit">
          <ChatNotification />
        </Typography>
        <Typography variant="body2" color="inherit">
          App Version : 0.1.0
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
