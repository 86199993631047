import { takeLatest, call, put } from 'redux-saga/effects';
import {
  SUBMIT_HANDOVER_NOTE,
  FETCH_HANDOVER_NOTE,
  UPDATE_HANDOVER_NOTE,
} from '../actions/handoverActions';
import {
  submitHandoverNoteSuccess,
  submitHandoverNoteFailure,
  fetchHandoverNoteSuccess,
  fetchHandoverNoteFailure,
  updateHandoverNoteSuccess,
  updateHandoverNoteFailure,
} from '../actions/handoverActions';
import { fetchWithAuth } from '../../utils/fetchApi';

function* submitHandoverNoteSaga(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/handovers/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });

    if (!response.ok) {
      throw new Error('Handover note add failed');
    }
    const data = yield response.json();
    yield put(submitHandoverNoteSuccess(data, "Handover note added successfully"));
  } catch (error) {
    yield put(submitHandoverNoteFailure(error.message));
  }
}


function* fetchHandoverNoteSaga(action) {
  try {
    const response = yield call(fetchWithAuth, `/api/handovers/${action.payload}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Handover note fetch failed');
    }

    const data = yield response.json();
    yield put(fetchHandoverNoteSuccess(data));
  } catch (error) {
    yield put(fetchHandoverNoteFailure(error.message));
  }
}

function* updateHandoverNoteSaga(action) {
  try {
    const response = yield call(fetchWithAuth, `/api/handovers/${action.payload.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });

    if (!response.ok) {
      throw new Error('Handover note update failed');
    }
    const data = yield response.json();
    yield put(updateHandoverNoteSuccess(data, "Handover note updated successfully"));
  } catch (error) {
    yield put(updateHandoverNoteFailure(error.message));
  }
}

export default function* handoverSaga() {
  yield takeLatest(SUBMIT_HANDOVER_NOTE, submitHandoverNoteSaga);
  yield takeLatest(FETCH_HANDOVER_NOTE, fetchHandoverNoteSaga);
  yield takeLatest(UPDATE_HANDOVER_NOTE, updateHandoverNoteSaga);
}
