import React, { useEffect, useState } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,

} from '@mui/material';
import { useSharedData } from '../../../../utils/SharedDataContext';

export default function UserPreference({formData, setFormData}) {

  const { locationNames, participantNames, activityTypeNameIds } = useSharedData();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
    return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <FormControl fullWidth>
          <InputLabel>Participant</InputLabel>
          <Select
            name="participant"
            value={formData.participant}
            onChange={handleChange}
            label="Participant"
          >
            {participantNames.map((client) => (
              <MenuItem key={client} value={client}>{client}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={6}>
        <FormControl fullWidth >
          <InputLabel>Location</InputLabel>
          <Select
            name="location"
            value={formData.location}
            onChange={handleChange}
            label="Location"
          >
            {locationNames.map((client) => (
              <MenuItem key={client} value={client}>{client}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={6}>
        <FormControl fullWidth >
          <InputLabel>Activity Type</InputLabel>
          <Select
            name="activity_type"
            value={formData.activity_type}
            onChange={handleChange}
            label="Activity Type"
          >
            {activityTypeNameIds.map((type) => (
              <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={6}>
        <TextField
          fullWidth
          label="External System Id"
          name="external_system_id"
          value={formData.external_system_id}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
}