import React, { useEffect, useState }   from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Dialog } from '@mui/material';
import TimesheetEntryForm from './TimesheetEntryForm';

export default function AddTimeSheetDialog({handleClose, open, staffs, mode, timesheet}) {
  const [data, setData] = useState({});
  const [modeWatch, setModeWatch] = useState(mode);
  useEffect(()=>{
    setModeWatch(mode);

    if (mode === 'add' ) {
      setData({});
    } else {
      setData(timesheet);
    }
    
  },[mode, timesheet])

  const handleSubmit = (formData) => {
    handleClose(formData);
  };

  const handleReset = () => {
    // Any additional reset logic can go here
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
      <DialogTitle sx={{background:'linear-gradient(to left, darkred, navy)', color:'white', mb:2}}>{mode === 'add' ? 'Add Timesheet' : mode === 'modify' ? 'Modify Timesheet' : 'View Timesheet'}</DialogTitle>
        <DialogContent>
          <TimesheetEntryForm
            onSubmit={handleSubmit}
            onReset={handleReset}
            onClose={handleClose}
            staffNames={staffs}
            initialData={data}
            mode={modeWatch}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
