import React, { useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography, Alert, Grid, TextField } from '@mui/material';
import { activityTypeActions } from '../../../../redux/actions/activityTypeActions';
import AddActivityType from "./AddActivityType";
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  {
    field: 'activity_code',
    renderHeader: () => (
      <strong style={{color:'white'}}>
        {'Activity Code'}
      </strong>
    ),
    flex: 1,
    editable: false
  },
  {
    field: 'activity_name',
    renderHeader: () => (
      <strong style={{color:'white'}}>
        {'Activity Name'}
      </strong>
    ),
    flex: 1,
  },
  {
    field: 'activity_description',
    renderHeader: () => (
      <strong style={{color:'white'}}>
        {'Activity Description'}
      </strong>
    ),
    flex: 1,
  },
  {
    field: 'activity_type',
    renderHeader: () => (
      <strong style={{color:'white'}}>
        {'Billing Type'}
      </strong>
    ),
    flex: 1,
  },
  {
    field: 'pay_item_code',
    renderHeader: () => (
      <strong style={{color:'white'}}>
        {'Pay Item Code'}
      </strong>
    ),
    flex: 1,
  },
];

const ManageActivityTypes = () => {
  const { message, activityTypes, error } = useSelector((state) => state.activityType);
  const [open, setOpen] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const initialData = {
    id: null,
    activity_code: '',
    activity_name: '',
    activity_type: '',
    activity_description: '',
    is_primary_activity: true,
    pay_item_code: '',
    is_shift_time:false,
    external_system_id:''
  };
  const [activityData, setActivityData] = useState(initialData);
  const dispatch = useDispatch();
  const handleAddActivityType = () => {
    setOpen(true);
  }
  const handleGetActivityTypes=(e) =>{
    e.preventDefault();
    dispatch(activityTypeActions.getActivityTypesRequest(filter));
  }
  const [filter, setFilter] = useState({
    name: '',
    code: ''
  });
  const handleClose = (activityType) => {
    setOpen(false);
    if (activityType) {
      if (!activityType.id) {
        dispatch(activityTypeActions.addActivityTypeRequest(activityType));
      } else {
        dispatch(activityTypeActions.modifyActivityTypeRequest(activityType));
      }
    }
    setActivityData(initialData);
  };

  const onDeleteActivity = () => {
    if (rowSelectionModel && rowSelectionModel.length === 1){
      dispatch(activityTypeActions.deleteActivityTypeRequest(rowSelectionModel[0]));
    }
    else {
      alert('Select a row to delete');
    }
  }

  const onModifyActivity = () => {
    
    if (rowSelectionModel && rowSelectionModel.length === 1){
      let selectedActvities = activityTypes.filter(item => item.id === rowSelectionModel[0])
      if (selectedActvities && selectedActvities.length === 1 ) {
        setActivityData({
          ...selectedActvities[0],
          is_primary_activity: selectedActvities[0].is_primary_activity === 1 ? true: false,
          is_shift_time: selectedActvities[0].is_shift_time === 1 ? true: false
        });
        console.log(selectedActvities[0]);
        setOpen(true);
      }
    }
    else {
      alert('Select a row to modify');
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };
  return (
    <Box sx={{ mb: 2, ml:2 }} >
      <Typography variant="h6">Activity Types</Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {message && <Alert severity="success">{message}</Alert>}
      <Box sx={{ mt: 2 }}>
        <form onSubmit={handleGetActivityTypes}>
          <Grid container spacing={2}>
            
            {/* <Grid item xs={4} sm={3}>
              <TextField
                fullWidth
                label="Activity Name"
                name="name"
                value={filter.name}
                onChange={handleChange}
                disabled
              />
            </Grid>
            <Grid item xs={4} sm={3}>
              <TextField
                fullWidth
                label="Activity Code"
                name="code"
                value={filter.code}
                onChange={handleChange}
                disabled
              />
            </Grid> */}
            <Grid item sx={{mt:2}}>
              <Button variant="contained" color="primary" type="submit">
                Refresh
              </Button>
              
              <Button variant="contained" sx={{ ml: 4 }} color="secondary" onClick={handleAddActivityType}>
                Add
              </Button>
              <Button variant="contained" sx={{ ml: 2 }} color="warning" onClick={onModifyActivity}>
                Modify
              </Button>
              <Button variant="contained" sx={{ ml: 2 }} color="error" onClick={onDeleteActivity}>
                Delete
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box sx={{ mt:2, height: 500, width: '100%' }}>
        <DataGrid autoHeight getRowId={(row) => row.id}
        sx={{ "& .MuiDataGrid-withBorderColor": { backgroundColor: '#4386f4' }}}
          rows={activityTypes}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 7,
              },
            },
          }}
          pageSizeOptions={[7]}
          checkboxSelection
          disableRowSelectionOnClick
          disableMultipleRowSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            console.log("newRowSelectionModel",newRowSelectionModel);
             setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
        />
      </Box>
      <AddActivityType
        handleClose={handleClose}
        initialData={activityData}
        open={open}
      />
    </Box>
  );

}

export default ManageActivityTypes;