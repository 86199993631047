import React, { useEffect, useState } from 'react';
import {Box, Typography} from '@mui/material';
import FortnightViewDataGrid from './FortnightViewDataGrid';
import { Accordion, AccordionSummary, AccordionDetails } from '../../common/TimeSheetStyledAccordion';
import { useSharedData } from '../../../utils/SharedDataContext';


const getFortnight = (dateString) => {
  // Parse the input date string
  const date = new Date(dateString * 1000);
  
  // Get the day of the week (0-6, where 0 is Sunday)
  const dayOfWeek = date.getDay();
  
  // Calculate days to subtract to get to the previous Monday
  const daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  
  // Calculate the start of the fortnight (previous Monday)
  const fortnightStart = new Date(date);
  fortnightStart.setDate(date.getDate() - daysToMonday);
  
  // Calculate the end of the fortnight (this week's Sunday)
  const fortnightEnd = new Date(date);
  const daysToSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
  fortnightEnd.setDate(date.getDate() + daysToSunday);
  
  // Format dates as strings
  const formatDate = (d) => d.toLocaleDateString();
  
  return {
      start: formatDate(fortnightStart),
      end: formatDate(fortnightEnd)
  };
}

const getFortnightRollup = (data) => {
  const rollupTimesheet = [];
  let row_id = 0;
  if (data && Array.isArray(data)) {
    data.forEach((timesheetEntry) => {
      if (timesheetEntry.timesheetData && Array.isArray(timesheetEntry.timesheetData)) {
        timesheetEntry.timesheetData.forEach((shiftEntry) => {
            const date_range = getFortnight(shiftEntry.shift_date);
            const currShiftRange = "From "+date_range.start + " to "+date_range.end;
            let existingEntry = rollupTimesheet.find(entry => entry.staff_id === timesheetEntry.staff_id);

            if (!existingEntry) {
              existingEntry = {
                staff_id: timesheetEntry.staff_id,
                id: shiftEntry.user_id,
                fortnightRollupData: []
              };
              rollupTimesheet.push(existingEntry);
            }

            let existingShift = existingEntry.fortnightRollupData.find(
              shift => 
              (
                shift.shift_category === shiftEntry.shift_category && 
                shift.shift_range === currShiftRange
              ));

            if (existingShift) {
              existingShift.hours_approved += shiftEntry.hours_approved;
            } else {
              existingEntry.fortnightRollupData.push({
                id: row_id++,
                user_id: shiftEntry.user_id,
                staff_id: timesheetEntry.staff_id,
                shift_category: shiftEntry.shift_category,
                hours_approved: shiftEntry.hours_approved,
                shift_range: currShiftRange,
                shift_item_code : shiftEntry.pay_item_code,
              });
          }
        });
      }
    });
  }
  return rollupTimesheet;
};

const FortnightViewAccordian = ({data}) => {
  const { staffNameIds  } = useSharedData();
  const [fortnightRollupData, setFortnightRollupData] = useState(null);
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(()=>{
    if (data && data[0]) {
      setFortnightRollupData(getFortnightRollup(data));
      setExpanded(data[0].staff_id);
    }
  },[data])
  return (
    <Box>
      {
        fortnightRollupData && fortnightRollupData.map( (employee, index) => (
          <Accordion key={index}  expanded={ expanded === employee.staff_id} onChange={handleChange(employee.staff_id)}>
            <AccordionSummary sx={{background:'linear-gradient(to left, darkred, navy)', color:"white", borderRadius: '6px'}} id={employee.staff_id+"-panel-header"}>
              <Typography sx={{fontWeight:'bold'}}>{staffNameIds.find(s => s.id === employee.staff_id).name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FortnightViewDataGrid key={index} data={employee.fortnightRollupData} id={employee.id} />
            </AccordionDetails>
          </Accordion>    
        ))
      }
    </Box>
  );
}


export default FortnightViewAccordian;