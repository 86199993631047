import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
} from "@mui/material";
import SearchComponent from "./timesheet/SearchComponent";
import TimeSheetAccordian from "./timesheet/TimeSheetAccordian";
import { styled } from "@mui/material/styles";
import {
  searchTimesheetsRequest,
  addNewTimesheetRequest,
  updateTimesheetRequest
} from "../../redux/actions/timesheetActions";
import AddTimeSheetDialog from './timesheet/AddTimeSheetDialog';
import { useSharedData } from '../../utils/SharedDataContext';
import IncidentForm from "./incident/IncidentForm";
import { incidentActions } from "../../redux/actions/incidentActions";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const TimeSheet = () => {
  const dispatch = useDispatch();
  const { loading, data, error } = useSelector((state) => state.timesheet);
  const incidentData = useSelector((state) => state.incident);
  const { staffNameIds  } = useSharedData();
  const [timesheet, setTimesheet] = useState({});
  const [incident, setIncident] = useState({});
  const [mode, setMode] = useState('add');
  const [openTimeSheet, setOpenTimeSheet] = useState(false);
  const [openIncident, setOpenIncident] = useState(false);

  const handleSearch = (e) => {
    dispatch(searchTimesheetsRequest(e));
  };

  const handleIncidentClose = (formData) => {
    setOpenIncident(false);
    if (formData && formData.incident_details && mode === 'add'){
      console.log('newIncident formData==', formData);
      dispatch(incidentActions.addIncidentRequest(formData));
    }
  }

  const handleTimeSheetClose = (formData) => {
    setOpenTimeSheet(false);
    if (formData && formData.staff_id && mode === 'add'){
      const newTimesheet = {
        ...formData, 
        activity_with: formData.activity_with.length > 0 ? formData.activity_with.join(", "):null ,
        start_time:(formData.start_time.valueOf()/1000 | 0),
        end_time:(formData.end_time.valueOf()/1000 | 0),
        ...(formData.sleepover_start && {sleepover_start: (formData.sleepover_start.valueOf()/1000 | 0)})
      };
      dispatch(addNewTimesheetRequest(newTimesheet));
    } else if (formData && formData.staff_id && mode ==='modify') {
      console.log(' modify formData = ', formData);
      const start_time = (formData.start_time.valueOf()/1000 | 0)
      const end_time = (formData.end_time.valueOf()/1000 | 0)
      const newTimesheet = {
        ...formData, 
        activity_with: formData.activity_with.length > 0 ? formData.activity_with.join(", "):null ,
        start_time: start_time,
        end_time: end_time,
        total_time: (end_time - start_time)/3600,
        ...(formData.sleepover_start && {sleepover_start: (formData.sleepover_start.valueOf()/1000 | 0)})
      };
      dispatch(updateTimesheetRequest(newTimesheet));
    }
  };

  const handleAddNew = () => {
    setMode('add');
    setTimesheet({});
    setOpenTimeSheet(true);
  }

  const handleTimeSheetEdit = (timesheetData) => {
    setMode('modify');
    setTimesheet(timesheetData);
    setOpenTimeSheet(true);
  }

  const handleTimeSheetView = (timesheetData) => {
    setMode('view');
    setTimesheet(timesheetData);
    setOpenTimeSheet(true);
  }

  const handleViewProcessed = () => {
    console.log('handleViewProcessed Clicked!!');
  }

  const handleAddIncident = () => {
    console.log('add Incident Clicked!!');
    setMode('add');
    setIncident({});
    setOpenIncident(true);
  }
  return (
    <>
      <Box sx={{ p: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2}>
            <Item>
              <Typography component="div" sx={{ mb: 1, fontWeight:'bold' }}>
                Time Sheet Data
              </Typography>
            </Item>
          </Grid>
          <Grid item sx={{mb: 1}} xs={12} md={10}>
            <Item>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: { xs: 'stretch', sm: 'flex-start' },
                  width: '100%',
                  gap: 2,
                }}
              >
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={handleAddNew}
                  sx={{
                    width: { xs: '100%', md: 'auto' },
                    alignSelf: { md: 'flex-start' },
                  }}
                >
                  Add TimeSheet
                </Button>
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={handleAddIncident}
                  sx={{
                    width: { xs: '100%', md: 'auto' },
                    alignSelf: { md: 'flex-start' },
                  }}
                >
                  Add Incident
                </Button>
                {
                matchMedia('(min-width: 800px)').matches && (
                      <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={handleViewProcessed}
                        sx={{
                          width: { xs: '100%', md: 'auto' },
                          alignSelf: { md: 'flex-start' },
                        }}
                      >
                        View Processed Timesheets
                      </Button>
                )
                }
                <Box sx={{ml:2}}>
                {loading && <Typography>Loading...</Typography>}
                {error && <Typography color="error">{error}</Typography>}
                </Box>
              </Box>
            </Item>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} md={2}>
            <Item>
              <SearchComponent
                employeesList={staffNameIds}
                onSearch={handleSearch}
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={10}>
            <Item>
              <TimeSheetAccordian data={data} onEdit={handleTimeSheetEdit} onView={handleTimeSheetView}/>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <AddTimeSheetDialog
        open={openTimeSheet}
        handleClose={handleTimeSheetClose}
        staffs={staffNameIds}
        timesheet={timesheet}
        mode={mode}
      />
      <IncidentForm
        open={openIncident}
        handleClose={handleIncidentClose}
        mode={'add'}
        incidentData={incident}
      />
    </>
  );
};

export default TimeSheet;
