// sagas/index.js
import { all } from 'redux-saga/effects';
import watchAuth from './authSaga';
import watchUser from './userSaga';
import watchTimesheet from './timesheetSaga';
import uploaderSaga from './uploaderSaga';
import processShiftSaga from './shiftSaga';
import watchProcessedTimesheet from './processedTimesheetSaga';
import documentSaga from './documentSaga';
import participantSaga from './participantSaga';
import locationSaga from './locationSaga';
import activityTypeSaga from './activityTypeSaga';
import templateSaga from './templateSaga';
import watchReportSagas from './reportSagas';
import notificationSaga from './notificationSaga';
import handoverSaga from './handoverSaga';
import { ruleSaga } from './ruleSaga';
import { integrationSaga } from './integrationSaga';
import incidentSaga from './incidentSaga';

export default function* rootSaga() {
  yield all([
    watchAuth(),
    watchUser(),
    watchTimesheet(),
    uploaderSaga(),
    processShiftSaga(),
    watchProcessedTimesheet(),
    documentSaga(),
    participantSaga(),
    locationSaga(),
    activityTypeSaga(),
    templateSaga(),
    watchReportSagas(),
    notificationSaga(),
    handoverSaga(),
    ruleSaga(),
    integrationSaga(),
    incidentSaga(),
  ]);
}
