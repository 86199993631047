import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Popover, Box, Button, Alert, Typography, Tooltip, MenuItem, Grid, TextField,
  FormControl, InputLabel, Select
 } from '@mui/material';
import { ruleActions } from '../../../../redux/actions/ruleActions';
import AddRule from "./AddRule";
import { DataGrid } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSharedData } from "../../../../utils/SharedDataContext";


const ManageRules = () => {
  const { staffNameIds } = useSharedData();
  const loggedInUser = useSelector(state => state.auth.user)
  const columns = [
    {
      field: 'rule_name',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'Rule Name'}
        </strong>
      ),
      flex: 1,
      editable: false
    },
    {
      field: 'category',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'Rule Category'}
        </strong>
      ),
      flex: 1,
      editable: false
    },
    {
      field: 'staff_id',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'Staff Name'}
        </strong>
      ),
      flex: 1,
      editable: false,
      renderCell: ({ row }) => {
        return (
          row.staff_id ?
          staffNameIds.find(s => s.id === row.staff_id)?.name|| <span style={{background:'lightyellow', border: '1px solid',borderRadius:'6px'}} >Eazify</span>
          :
          ""
        )
      },
    },
    {
      field: 'rule_object',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'Rule Description'}
        </strong>
      ),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Tooltip title={JSON.stringify(row.rule_object)} >
            <ExpandMoreIcon onClick={(e) => handleIconClick(e, row.rule_object)}/>
          </Tooltip>
        )
      },
    },
  ];

  const { message, rules, error } = useSelector((state) => state.rule);
  const [open, setOpen] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const initialData = {
    id: null,
    rule_name: '',
    category: '',
    staff_id:'',
    rule_object: {}
  };
  const [ruleData, setRuleData] = useState(initialData);
  const dispatch = useDispatch();
  const handleAddRule = () => {
    setRuleData(initialData);
    setOpen(true);
  }
  const handleGetRules=(e) =>{
    e.preventDefault()
    dispatch(ruleActions.getRulesRequest(filter));
  }

  useEffect(()=>{
    if (!rules || rules.length == 0) {
      dispatch(ruleActions.getRulesRequest());
    }
  },[]);

  const handleClose = (ruleType) => {
    setOpen(false);
    setRuleData(initialData);

  };

  const onDeleteRule = () => {
    if (rowSelectionModel && rowSelectionModel.length === 1){
      let selectedRules = rules.filter(item => item.id === rowSelectionModel[0])
      if (selectedRules && selectedRules.length === 1 ) {
        const selectedRule = selectedRules[0];
        if (selectedRule.category === 'Shift Time') {
          dispatch(ruleActions.deleteRuleRequest(rowSelectionModel[0]+'?shift=Y'));
        } else {
          dispatch(ruleActions.deleteRuleRequest(rowSelectionModel[0], selectedRule));
        }
      }      
    }
    else {
      alert('Select a row to delete');
    }
  }

  const onModifyRule = () => {
    
    if (rowSelectionModel && rowSelectionModel.length === 1){
      let selectedRules = rules.filter(item => item.id === rowSelectionModel[0])
      if (selectedRules && selectedRules.length === 1 ) {
        const selectedRule = selectedRules[0];
        console.log('selectedRule= ', selectedRule);
        if (selectedRule.category === 'Shift Time' && selectedRule.staff_id === 1  && loggedInUser.id !== selectedRule.staff_id) {
          alert('You cannot modify system wide rule.');
          return;
        }
        console.log('selectedRule=>',selectedRule);
        setRuleData(selectedRule);
        
        setOpen(true);
      }
    }
    else {
      alert('Select a row to modify');
    }
  }
  const [filter, setFilter] = useState({
    category: '',
    rule_name: ''
  });
  const [ruleObject, setRuleObject] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleIconClick = (event, rule_obj) => {
    setAnchorEl(event.currentTarget);
    setRuleObject(rule_obj);
  };
  
  const handlePopOverClose = () => {
    setAnchorEl(null);
  };
  
  const openPopOver = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };
  return (
    <Box sx={{ mb: 2, ml:2 }} >
      <Typography variant="h6">Rules</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {message && <Alert severity="success">{message}</Alert>}
      <Box sx={{ mt: 2 }}>
        <form onSubmit={handleGetRules}>
          <Grid container spacing={2}>
            
            {/* <Grid item xs={4} sm={3}>
              <FormControl fullWidth >
                <InputLabel id="category-type-label">Category</InputLabel>
                <Select
                  labelId="category-type-label"
                  name="category"
                  value={filter.category}
                  onChange={handleChange}
                  label="Category"
                >
                  <MenuItem value="Validation">Validation</MenuItem>
                  <MenuItem value="Shift Time">Shift Time</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={3}>
              <FormControl fullWidth>
                <TextField
                  label="Rule Name"
                  name="rule_name"
                  value={filter.rule_name}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid> */}
            <Grid item sx={{mt:2}}>
              <Button variant="contained" color="primary" type="submit">
                Refresh
              </Button>
              
              <Button variant="contained" sx={{ ml: 4 }} color="secondary" onClick={handleAddRule}>
                Add
              </Button>
              <Button variant="contained" sx={{ ml: 2 }} color="warning" onClick={onModifyRule}>
                Modify
              </Button>
              <Button variant="contained" sx={{ ml: 2 }} color="error" onClick={onDeleteRule}>
                Delete
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>

      <Box sx={{ mt:2, height: 500, width: '100%' }}>
        <DataGrid autoHeight getRowId={(row) => row.id}
        sx={{ "& .MuiDataGrid-withBorderColor": { backgroundColor: '#4386f4' }}}
          rows={rules}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 7,
              },
            },
          }}
          pageSizeOptions={[7]}
          checkboxSelection
          disableRowSelectionOnClick
          disableMultipleRowSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            console.log("newRowSelectionModel",newRowSelectionModel);
             setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
        />
      </Box>
      <AddRule
        handleClose={handleClose}
        initialData={ruleData}
        open={open}
      />
      <Popover
        id={id}
        open={openPopOver}
        anchorEl={anchorEl}
        onClose={handlePopOverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MiniTable rule_object={ruleObject} />
      </Popover>
    </Box>
  );

}
const MiniTable = ({ rule_object }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Attribute Name</th>
          <th>Attribute Value</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(rule_object).map((key) => (
          <tr key={key}>
            <td>{key}</td>
            <td>{rule_object[key]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ManageRules;