// selectors.js

// These are pure functions that select and transform data from the state
export const getLocationNames = (state) => state.location.locations && state.location.locations.map(loc => loc.location_name);
export const getParticipantNames = (state) => state.participant.participants && state.participant.participants.map(pn => pn.first_name + ' ' + pn.last_name);
export const getActivityTypeNames = (state) => state.activityType.activityTypes && state.activityType.activityTypes.map(act => act.activity_name);
export const getStaffNames = (state) => state.user.data &&  state.user.data.map( u => u.first_name+' '+u.last_name );

export const getActivityTypeNameIds = (state) => state.activityType.activityTypes && state.activityType.activityTypes.map(act => ({name: act.activity_name, id:act.id}));
export const getActivityPrimaryTypeNameIds = (state) => state.activityType.activityTypes && state.activityType.activityTypes.filter(act => (act.is_primary_activity === 1)).map(act => ({name: act.activity_name, id:act.id}));
export const getActivitySecondaryTypeNameIds = (state) => state.activityType.activityTypes && state.activityType.activityTypes.filter(act => (act.is_primary_activity === 0)).map(act => ({name: act.activity_name, id:act.id}));
export const getStaffNameIds = (state) => state.user.data &&  state.user.data.map( u => ({name:u.first_name+' '+u.last_name, id:u.id}) );
export const getLocationNameIds =  (state) => state.location.locations && state.location.locations.map(loc => ({name:loc.location_name, id:loc.id}));
export const getParticipantNameIds = (state) => state.participant.participants && state.participant.participants.map(pn => ({name:pn.first_name + ' ' + pn.last_name, id:pn.id}));

export const getUserPreference = (state) => state.auth.user.user_pref
