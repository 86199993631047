import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { locationActions } from '../redux/actions/locationActions';
import { participantActions } from '../redux/actions/participantActions';
import { activityTypeActions } from '../redux/actions/activityTypeActions';
import { getUsersRequest } from '../redux/actions/userActions';
import { 
  getLocationNames, 
  getParticipantNames,
  getActivityTypeNames,
  getStaffNames,
  getStaffNameIds,
  getLocationNameIds,
  getParticipantNameIds,
  getActivityTypeNameIds,
  getActivityPrimaryTypeNameIds,
  getActivitySecondaryTypeNameIds
} from '../redux/selectors';

const SharedDataContext = createContext();

export const SharedDataProvider = ({ children }) => {
  const dispatch = useDispatch();
  const staffs = useSelector(state => state.user.data);
  const locations = useSelector(state => state.location.locations);
  const participants = useSelector(state => state.participant.participants);
  const activityTypes = useSelector(state => state.activityType.activityTypes);
  const staffNames = useSelector(getStaffNames);
  const locationNames = useSelector(getLocationNames);
  const participantNames = useSelector(getParticipantNames);
  const activityTypeNames = useSelector(getActivityTypeNames);
  const staffNameIds = useSelector(getStaffNameIds);
  const locationNameIds = useSelector(getLocationNameIds);
  const participantNameIds = useSelector(getParticipantNameIds);
  const activityTypeNameIds = useSelector(getActivityTypeNameIds);
  const activityPrimaryTypeNameIds = useSelector(getActivityPrimaryTypeNameIds);
  const activitySecondaryTypeNameIds = useSelector(getActivitySecondaryTypeNameIds);


  useEffect(() => {
    if (!locations || locations.length === 0) {
      dispatch(locationActions.getLocationsRequest());
    }
    if (!participants || participants.length === 0) {
      dispatch(participantActions.getParticipantsRequest());
    }
    if (!activityTypes || activityTypes.length === 0) {
      dispatch(activityTypeActions.getActivityTypesRequest());
    }
    if (!staffs || staffs.length === 0) {
      dispatch(getUsersRequest());
    }
  }, []);

  return (
    <SharedDataContext.Provider value={{ 
      locations,
      participants,
      activityTypes,
      staffs,
      staffNames,
      locationNames,
      participantNames,
      activityTypeNames,
      staffNameIds,
      locationNameIds,
      participantNameIds,
      activityTypeNameIds,
      activityPrimaryTypeNameIds,
      activitySecondaryTypeNameIds
      }}>
      {children}
    </SharedDataContext.Provider>
  );
};

export const useSharedData = () => {
  const context = useContext(SharedDataContext);
  if (!context) {
    throw new Error('useSharedData must be used within a SharedDataProvider');
  }
  return context;
};