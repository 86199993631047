import { replaceObjectInArrayById } from '../../utils/DataUtils';

const initialState = {
  incidents:[],
  message:null,
  loading: false,
  error: null,
};
  
const incidentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_INCIDENT_REQUEST':
    case 'GET_INCIDENTS_REQUEST':
      return { ...state, loading: true, error: null, message: null };
    case 'ADD_INCIDENT_SUCCESS':
      return {
        ...state,
        loading: false,
        error:null,
        incidents: [...state.incidents, action.payload],
        message: 'Successfully added the incident'
        
      };
    case 'MODIFY_INCIDENT_SUCCESS':
      return {
        ...state,
        loading: false,
        error:null,
        incidents: [...replaceObjectInArrayById([...state.incidents], action.payload)],
        message: 'Successfully modified the incident'
      };
    case 'DELETE_INCIDENT_SUCCESS':
      return {
        ...state,
        loading: false,
        incidents: state.incidents.filter(l => l.id !== action.id),
        message: action.payload,
        error:null
      };
    case 'GET_INCIDENTS_SUCCESS':
      return { ...state, loading: false, error:null,
        incidents: [...action.payload]
       };
    case 'ADD_INCIDENT_FAILURE':
    case 'GET_INCIDENTS_FAILURE':
      return { ...state, loading: false, error: action.payload };
    case 'CLEAR_ERROR':
      return { ...state, error: null, message: null};
    default:
      return state;
  }
};

export default incidentReducer;