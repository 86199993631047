import React, { useEffect, useState } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,

} from '@mui/material';

export default function UserSettings({formData, setFormData}) {

  const employmentTypes = ["Casual", "Full-time", "Part-time"];
  const rateTypes = ["Ordinary Hours", "Ordinary Hours-SC2", "Ordinary Hours-SC3", "Ordinary Hours-SCS"];
  const overtimeCalcMethods = ["Daily", "Fortnightly"];
  const travelAllowances = ["Fixed", "Per-Hour"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
    return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <FormControl fullWidth>
          <InputLabel>Employment Type</InputLabel>
          <Select
            name="employment_type"
            value={formData.employment_type}
            onChange={handleChange}
            label="Employment Type"
          >
            {employmentTypes.map((e) => (
              <MenuItem key={e} value={e}>{e}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={6}>
        <FormControl fullWidth >
          <InputLabel>Ordinary Rate Type</InputLabel>
          <Select
            name="rate_type"
            value={formData.rate_type}
            onChange={handleChange}
            label="Ordinary Rate Type"
          >
            {rateTypes.map((r) => (
              <MenuItem key={r} value={r}>{r}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={6}>
        <FormControl fullWidth >
          <InputLabel>Overtime Calculation</InputLabel>
          <Select
            name="overtime_method"
            value={formData.overtime_method}
            onChange={handleChange}
            label="Overtime Calculation"
          >
            {overtimeCalcMethods.map((o) => (
              <MenuItem key={o} value={o}>{o}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={6}>
        <FormControl fullWidth >
          <InputLabel>Travel Allowance</InputLabel>
          <Select
            name="travel_allowance"
            value={formData.travel_allowance}
            onChange={handleChange}
            label="Travel Allowance"
          >
            {travelAllowances.map((t) => (
              <MenuItem key={t} value={t}>{t}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}