import React,{ useState } from 'react';
import { Box,  Typography, Tab, Tabs, Grid, Paper } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { searchProcessedTimesheetsRequest, saveHoursAdjustedRequest, saveCategoryChangeRequest } from '../../redux/actions/timesheetActions'
import { styled } from "@mui/material/styles";
import FortnightViewAccordian from './processedTimesheet/FortnightViewAccordian';
import DailyViewAccordian from './processedTimesheet/DailyViewAccordian';
import SearchProcessedTimesheet from './processedTimesheet/SearchProcessedTimesheet';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CustomTabPanel from '../common/CustomTabPanel';
import { useSharedData } from '../../utils/SharedDataContext';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const TimesheetsProcessed = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const {staffNameIds} = useSharedData();
  const dispatch = useDispatch();
  const { processing, processed, error, message } = useSelector((state) => state.processedTimesheet);

  const handleSearch = (e) => {
    dispatch(searchProcessedTimesheetsRequest(e));
  };

  const handleDataSave = (d) => {
    dispatch(saveHoursAdjustedRequest(d));
  }

  const handleSleepoverRequest = (d) => {
    dispatch(saveCategoryChangeRequest(d))
  }

  return (
    <Box p={2}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
          <Item>
            <SearchProcessedTimesheet
              employeesList={staffNameIds}
              onSearch={handleSearch}
            />
          </Item>
        </Grid>
        <Grid item xs={12} md={10}>
          <Item>
            <Typography  component="div" sx={{ mb: 1, fontWeight:'bold' }}>
              Processed Timesheets 
            </Typography>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Fortnight View"/>
              <Tab label="Daily View" iconPosition="end" icon={<EditNoteIcon />} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              {processing ? "Loading":
                <FortnightViewAccordian data={processed}/>
              }
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              {processing ? "Loading":
                <DailyViewAccordian data={processed}
                 onEditCompletion={handleDataSave}
                 handleSleepoverRequest={handleSleepoverRequest} 
                />
              }
            </CustomTabPanel>

          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TimesheetsProcessed;
