import React, { useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '../../common/TimeSheetStyledAccordion';
import {Box, Typography} from '@mui/material';
import DailyViewDataGrid from './DailyViewDataGrid';
import { useSharedData } from '../../../utils/SharedDataContext';


const DailyViewAccordian = ({data, onEditCompletion, handleSleepoverRequest}) => {

  const { staffNameIds  } = useSharedData();
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleHourAdjust = (changeData) => {
    onEditCompletion(changeData);
  }

  useEffect(()=>{
    if (data && data[0]) {
      setExpanded(data[0].staff_id);
    }
  },[])
  return (
    <Box>
      {
        data && data.map( (employee, index) => (
          <Accordion key={index}  expanded={ expanded === employee.staff_id} onChange={handleChange(employee.staff_id)}>
            <AccordionSummary sx={{background:'linear-gradient(to left, darkred, navy)', color:"white", borderRadius: '6px'}} id={employee.staff_id+"-panel-header"}>
              <Typography sx={{fontWeight:'bold'}}>{staffNameIds.find(s => s.id === employee.staff_id).name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DailyViewDataGrid key={index} data={employee.timesheetData} 
              onHourAdjust={handleHourAdjust} 
              handleSleepoverRequest={handleSleepoverRequest}
              />
            </AccordionDetails>
          </Accordion>    
        ))
      }
    </Box>
  );
}


export default DailyViewAccordian;